var showedTitles = [0];
var titles = [];
titles[0] = [];
titles[0]['me'] = "I'm a";
titles[0]['title'] = 'Happily settled ;)';
titles[1] = [];
titles[1]['me'] = "I'm a";
titles[1]['title'] = 'Loving Father';
titles[2] = [];
titles[2]['me'] = "I'm a";
titles[2]['title'] = 'OCR-, Hyrox, & Triathlete';
titles[3] = [];
titles[3]['me'] = "I'm a";
titles[3]['title'] = 'TYPO3 Developer';
titles[4] = [];
titles[4]['me'] = "I'm a";
titles[4]['title'] = 'Passionate Cook';
titles[5] = [];
titles[5]['me'] = "I'm a";
titles[5]['title'] = 'PHP Developer';
titles[6] = [];
titles[6]['me'] = "I'm a";
titles[6]['title'] = 'Linux user';
titles[7] = [];
titles[7]['me'] = "I'm a";
titles[7]['title'] = 'Minimalist';
titles[8] = [];
titles[8]['me'] = "I'm a";
titles[8]['title'] = 'Front-End Developer';

function openModal(modal) {
    var $container = document.querySelector('.container');
    var $allModal = document.querySelectorAll('.modal');
    var $newModal = document.querySelector('.js-modal-' + modal);

    $container.classList.add('has-modal');
    removeClass($allModal, 'active');
    $newModal.classList.add('active');
}
function closeModal() {
    var $container = document.querySelector('.container');
    var $allModal = document.querySelectorAll('.modal');
    
    $container.classList.remove('has-modal');
    removeClass($allModal, 'active');
}
function updateTitle() {
    var randomIndex = getRandomTitle();
    var randomTitle = titles[randomIndex];

    if(randomIndex == false) {
        showContact();
    } else {
        var $title = document.querySelector('.title');
        var $me = document.querySelector('.me');

        $title.innerHTML = randomTitle['title'];
        $me.innerHTML = randomTitle['me'];
    }
}
function showContact() {
    var $message = document.querySelector('.message');
    $message.innerHTML = 'Drop me a message if you want to know more :)<br><a href="javascript:linkTo_UnCryptMailto(\'nbjmup;jogpAxbjczuft/ofu\');" rel="noopener" aria-label="E-Mail">E-Mail</a> | <a href="https://www.linkedin.com/in/waibytes/" target="_blank" rel="noopener" aria-label="LinkedIn">LinkedIn</a>';
}
function resetTitle() {
    showedTitles = [];
    showedTitles.push(0);
    
    var $message = document.querySelector('.message');
    $message.innerHTML = '<span class="hello">Hello world!</span> <span class="me">'+titles[0]['me']+'</span> <span class="title">'+titles[0]['title']+'</span>';
}
function getRandomTitle() {
    var titleCount = titles.length;
    var randomIndex = Math.floor(Math.random() * (titleCount));

    if(showedTitles.length == titles.length) {
        return false;
    }

    if(showedTitles.indexOf(randomIndex) == -1) {
        showedTitles.push(randomIndex);
        return randomIndex;
    } else {
        return getRandomTitle();
    }
}
function UnCryptMailto(s) {
    var n = 0;
    var r = "";
    for( var i = 0; i < s.length; i++)
    {
        n = s.charCodeAt( i );
        if( n >= 8364 )
        {
            n = 128;
        }
        r += String.fromCharCode( n - 1 );
    }
    return r;
}
function linkTo_UnCryptMailto(s) {
    location.href=UnCryptMailto(s);
}
var callback = function(){
    var $toggleImprint = document.querySelector('.js-toggle-imprint');
    var $togglePrivacy = document.querySelector('.js-toggle-privacy');
    var $toggleTitle = document.querySelector('.title');
    var $toggleLogo = document.querySelector('.logo');
    var $modalCloseElements = document.querySelectorAll('.js-toggle-modal-close');

    if(typeof $toggleImprint !== 'undefined' &&  $toggleImprint !== null) {
        $toggleImprint.addEventListener("click", function() { 
            openModal('imprint');
        });
    }
    if(typeof $togglePrivacy !== 'undefined' &&  $togglePrivacy !== null) {
        $togglePrivacy.addEventListener("click", function() { 
            openModal('privacy');
        });
    }
    if(typeof $toggleTitle !== 'undefined' &&  $toggleTitle !== null) {
        $toggleTitle.addEventListener("click", function() { 
            updateTitle();
        });
    }
    if(typeof $toggleLogo !== 'undefined' &&  $toggleLogo !== null) {
        $toggleLogo.addEventListener("click", function() { 
            resetTitle();
            closeModal();
            callback();
        });
    }


    for (var i = 0; i < $modalCloseElements.length; i++) {
        $modalCloseElements[i].addEventListener('click', function() { 
            closeModal();
        });
    }
};


if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    callback();
} else {
    document.addEventListener("DOMContentLoaded", callback);
}

function removeClass(elements, myClass) {
    if (!elements) { return; }

    if (typeof(elements) === 'string') {
        elements = document.querySelectorAll(elements);
    }

    else if (elements.tagName) { elements=[elements]; }

    var reg = new RegExp('(^| )'+myClass+'($| )','g');

    for (var i=0; i<elements.length; i++) {
        elements[i].className = elements[i].className.replace(reg,' ');
    }
}